@keyframes ldio-q3ofc5j8el-o {
  0%    { opacity: 1; transform: translate(0 0) }
 49.99% { opacity: 1; transform: translate(80px,0) }
 50%    { opacity: 0; transform: translate(80px,0) }
100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-q3ofc5j8el {
  0% { transform: translate(0,0) }
 50% { transform: translate(80px,0) }
100% { transform: translate(0,0) }
}
.ldio-q3ofc5j8el div {
position: absolute;
width: 80px;
height: 80px;
border-radius: 50%;
top: 60px;
left: 20px;
}
.ldio-q3ofc5j8el div:nth-child(1) {
background: #0a0a0a;
animation: ldio-q3ofc5j8el 1s linear infinite;
animation-delay: -0.5s;
}
.ldio-q3ofc5j8el div:nth-child(2) {
background: #28292f;
animation: ldio-q3ofc5j8el 1s linear infinite;
animation-delay: 0s;
}
.ldio-q3ofc5j8el div:nth-child(3) {
background: #0a0a0a;
animation: ldio-q3ofc5j8el-o 1s linear infinite;
animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-ok04hn58ss8 {
width: 200px;
height: 200px;
display: inline-block;
overflow: hidden;
background: none;
}
.ldio-q3ofc5j8el {
width: 100%;
height: 100%;
position: relative;
transform: translateZ(0) scale(1);
backface-visibility: hidden;
transform-origin: 0 0; /* see note above */
}
.ldio-q3ofc5j8el div { box-sizing: content-box; }
/* generated by https://loading.io/ */