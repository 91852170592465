/* reset css */

*{
  padding: 0;
  margin: 0;
  border: 0;
}


*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


:focus, :active {
  outline: none;
}
a:focus, a:active {
  outline: none;
}


nav, footer, header, aside {
  display: block;
}


html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}


input, button, textarea {
  font-family: inherit;
}


input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a, a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

/* reset css */

.wrapper {
  height: 100%;
  max-width: 90%;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.toggle-btn {
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 20px 0;
}
.toggle-btn > button {
  width: 150px;
  height: 50px;
  font-size: 2rem;
  background-color: yellow;
  border-radius: 20px;
}

.toggle-btn > button:hover {
  background-color: rgb(207, 207, 10);
}