header{
  width: 100%;
  height: 75px;
  padding: 20px 10px;
}
.wrapper > h1{
  font-size: 3rem;
  margin-right: 2rem;
}
ul{
  height: 100%;
  display: flex;
  align-items: center;
}
ul > li{
  margin-right: 1.5rem;
  font-size: 1.5rem;
  transition: all .3s ease;

}
ul > li > a:hover{
  text-decoration:underline;
}

@media (max-width: 500px) {
  ul {
    display: none;
  }
}